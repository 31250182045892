import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { Helmet } from 'react-helmet';
import { GlobalStyle } from './src/styles/GlobalStyle'
import { extendTheme } from '@chakra-ui/react'
import { ChakraProvider } from '@chakra-ui/react';

const theme = extendTheme({
    components: {
        Heading: {
            sizes: {
                "md": {
                    fontSize: "md",
                    letterSpacing: '0.05rem',
                    lineHeight: '1vh',
                },
                "xl": {
                    fontSize: "xl",
                    letterSpacing: '0.05rem',
                    lineHeight: '1vh',
                },
                "2xl": {
                    fontSize: "2xl",
                    fontWeight: '800',
                    lineHeight: '2rem',
                },
                "3xl": {
                    fontSize: "3xl",
                    fontWeight: '800',
                    lineHeight: '2.5rem',
                },
                "4xl": {
                    fontSize: "4xl",
                    fontWeight: '800',
                    lineHeight: '2.2rem',
                },
                "5xl": {
                    fontSize: "5xl",
                    fontWeight: '800',
                    lineHeight: '3.2rem',
                },
                "6xl": {
                    fontSize: "6xl",
                    fontWeight: '900',
                    lineHeight: '6.5vh',
                },
                "7xl": {
                    fontSize: "7xl",
                    fontWeight: '900',
                    lineHeight: '5.5rem',
                },
                "8xl": {
                    fontSize: "8xl",
                    fontWeight: '900',
                    lineHeight: '8rem',
                },
                "9xl": {
                    fontSize: "9xl",
                    fontWeight: '900',
                    lineHeight: '9.5rem',
                },
            },
        }
    },
    fonts: {
        body: "Inter, sans-serif",
        heading: "Inter, sans-serif"
    },
    fontSizes: {
        xs: "0.75vh",
        sm: "0.875vh",
        md: "1rem",
        lg: "1.25rem",
        xl: "1.75h",
        "2xl": "2rem",
        "3xl": "2.3rem",
        "4xl": "2.8rem",
        "5xl": "3rem",
        "6xl": "4rem",
        "7xl": "5rem",
        "8xl": "7rem",
        "9xl": "9rem",
    },
    styles: {
        global: {
          h1: {
            color: '#B27746',
          }
        },
      },
})

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
    element,
}) => {
    return(
        <>
            <Helmet>
                <link rel='preconnect' href='https://fonts.googleapis.com'></link>
                <link rel="preconnect" href="https://fonts.gstatic.com"></link>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900" rel="stylesheet"></link>
                <title>Offal Exp S.A.</title>
                <meta name='description' content='We are Offal Exp. We specialize in beef offal. We are professionals in this business for more than 60 years, and our know-how was the key that has turned our Company into the most important one in the World in its field' />
            </Helmet>
            <ChakraProvider theme={theme}>
                <GlobalStyle />
                { element }
            </ChakraProvider>
        </>
    )
}